import lost from "./lost.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={lost} className="App-img" alt="labyrinth" />
        <p className="App-copyright"> © Maksym Kaharlytskyi / Unsplash</p>
        <p>Vous êtes perdu !</p>
      </header>
    </div>
  );
}

export default App;
